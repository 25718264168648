import { SelectableValue, TimeOption } from '@grafana/data';

export const quickOptions: TimeOption[] = [
  { from: 'now-5m', to: 'now', display: 'Последните 5 минути' },
  { from: 'now-15m', to: 'now', display: 'Последните 15 минути' },
  { from: 'now-30m', to: 'now', display: 'Последните 30 минути' },
  { from: 'now-1h', to: 'now', display: 'Последният час' },
  { from: 'now-3h', to: 'now', display: 'Последните 3 часа' },
  { from: 'now-6h', to: 'now', display: 'Последните 6 часа' },
  { from: 'now-12h', to: 'now', display: 'Последните 12 часа' },
  { from: 'now-24h', to: 'now', display: 'Последните 24 часа' },
  { from: 'now-2d', to: 'now', display: 'Последните 2 дни' },
  { from: 'now-7d', to: 'now', display: 'Последните 7 дни' },
  { from: 'now-30d', to: 'now', display: 'Последните 30 дни' },
  { from: 'now-90d', to: 'now', display: 'Последните 90 дни' },
  { from: 'now-6M', to: 'now', display: 'Последните 6 месеца' },
  { from: 'now-1y', to: 'now', display: 'Последната година' },
  { from: 'now-2y', to: 'now', display: 'Последните 2 години' },
  { from: 'now-5y', to: 'now', display: 'Последните 5 години' },
  { from: 'now-1d/d', to: 'now-1d/d', display: 'Вчера' },
  { from: 'now-2d/d', to: 'now-2d/d', display: 'Онзи ден' },
  { from: 'now-7d/d', to: 'now-7d/d', display: 'Този ден предишната седмица' },
  { from: 'now-1w/w', to: 'now-1w/w', display: 'Предишната седмица' },
  { from: 'now-1M/M', to: 'now-1M/M', display: 'Предишният месец' },
  { from: 'now-1Q/fQ', to: 'now-1Q/fQ', display: 'Предишната фискална четвърт' },
  { from: 'now-1y/y', to: 'now-1y/y', display: 'Предишната година' },
  { from: 'now-1y/fy', to: 'now-1y/fy', display: 'Предишната фискална година' },
  { from: 'now/d', to: 'now/d', display: 'Днес' },
  { from: 'now/d', to: 'now', display: 'Досега днес' },
  { from: 'now/w', to: 'now/w', display: 'Тази седмица' },
  { from: 'now/w', to: 'now', display: 'Досега тази седмица' },
  { from: 'now/M', to: 'now/M', display: 'Този месец' },
  { from: 'now/M', to: 'now', display: 'Досега този месец' },
  { from: 'now/y', to: 'now/y', display: 'Тази година' },
  { from: 'now/y', to: 'now', display: 'Досега тази година' },
  { from: 'now/fQ', to: 'now', display: 'Досега тази фискална четвърт' },
  { from: 'now/fQ', to: 'now/fQ', display: 'Тази фискална четвърт' },
  { from: 'now/fy', to: 'now', display: 'Досега тази фискална година' },
  { from: 'now/fy', to: 'now/fy', display: 'Тази фискална година' },
];

export const monthOptions: Array<SelectableValue<number>> = [
  { label: 'Януари', value: 0 },
  { label: 'Февруари', value: 1 },
  { label: 'Март', value: 2 },
  { label: 'Април', value: 3 },
  { label: 'Май', value: 4 },
  { label: 'Юни', value: 5 },
  { label: 'Юли', value: 6 },
  { label: 'Август', value: 7 },
  { label: 'Септември', value: 8 },
  { label: 'Октомври', value: 9 },
  { label: 'Ноември', value: 10 },
  { label: 'Декември', value: 11 },
];
